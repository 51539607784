body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  padding-bottom: 70px;
}

.sr-only {
  display: none;
}
